import React, { FC, useEffect, useState } from "react";
import { List, Header, Segment } from "semantic-ui-react";
import moment from "moment";
import { ListAllResidentCallHistory } from "../../services/CallHistory";
import { sendToast } from "../../util";

interface Props {
    startDate: Date;
    endDate: Date;
    showOnlyTopFive?: boolean;
    height?: string;
}

interface CallRecord {
    residentName: string;
    totalCalls: number;
}

const TopCallRecipients: FC<Props> = (props) => {
    const [callRecords, setCallRecords] = useState<CallRecord[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    // Sort and optionally limit to top 5
    const sortedCallRecords = [...callRecords]
        .sort((a, b) => b.totalCalls - a.totalCalls)
        .slice(0, props.showOnlyTopFive ? 5 : callRecords.length);

    const fetchCallData = async () => {
        try {
            setIsLoading(true);
            const formattedStartDate = moment(props.startDate).valueOf();
            const formattedEndDate = moment(props.endDate).valueOf();

            const callFilter = {
                status: "completed",
                startTimestamp: {
                    $gte: formattedStartDate,
                    $lt: formattedEndDate
                }
            };

            // Fetch completed calls
            const completedCalls = await ListAllResidentCallHistory(callFilter);

            // Group and count calls for participants
            const callMap = completedCalls.reduce((acc, call) => {
                const callerName = call.caller;
                const receiverName = call.reciever;

                // Add caller to the map
                if (!acc[callerName]) {
                    acc[callerName] = 0;
                }
                acc[callerName] += 1;

                // Add receiver to the map
                if (!acc[receiverName]) {
                    acc[receiverName] = 0;
                }
                acc[receiverName] += 1;

                return acc;
            }, {});

            // Convert the map to an array
            const callRecordsArray: CallRecord[] = Object.entries(callMap).map(([name, totalCalls]) => ({
                residentName: name,
                totalCalls: totalCalls as number
            }));

            // Sort the array by total calls in descending order
            const slicedArray = callRecordsArray.sort((a, b) => b.totalCalls - a.totalCalls).slice(0, props.showOnlyTopFive ? 5 : callRecordsArray.length);

            setCallRecords(slicedArray);
        } catch (error) {
            console.error("Error fetching call data", error);
            sendToast("error", error instanceof Error ? error.message : "An error occurred");
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        (async () => {
            await fetchCallData();
        })();
    }, []);

    const defaultStyle = {width: "300px"};
    const segmentStyle = props.height ? {...defaultStyle, height: props.height} : defaultStyle;
    return (
        <Segment style={segmentStyle} loading={isLoading}>
            <Header as="h4" >
                Calls
            </Header>
            <List divided verticalAlign="middle">
                {sortedCallRecords.map((record, index) => (
                    <List.Item key={index}>
                        <List.Content floated="right">
                            <span>{record.totalCalls}</span>
                        </List.Content>
                        <List.Content>
                            <span
                                style={{
                                    display: "inline-block",
                                    padding: "4px 8px",
                                    borderRadius: "4px",
                                }}
                            >
                                {record.residentName}
                            </span>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </Segment>
    );
};

export default TopCallRecipients;
