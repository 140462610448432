import React, { FC, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import TopRequesters from "../TopRequesters";
import TopCallRecipients from "../TopCallRecipients";
import TopMessages from "../TopMessages";
import { fetchDepartments } from "../../services/Departments";
import { sendToast } from "../../util";
import TopStaffClosingRequests from "../TopStaffClosingRequests";
import TopVisitors from "../TopVisitors";
import TopMissedMeals from "../TopMissedMeals";

interface Props {
  startDate: Date | undefined;
  endDate: Date | undefined;
  facilityTimeZone: string;
}

const ReportsTopFive: FC<Props> = (props) => {
  const [transportDepartmentId, setTransportDepartmentId] = useState('');
  const [maintenanceDepartmentId, setMaintenanceDepartmentId] = useState('');

  const fetchFacilityDepartments = async () => {
    try {
      const departments = await fetchDepartments();
      const transportDepartment = departments.find((department) => department.Name === 'Transport');
      const maintenanceDepartment = departments.find((department) => department.Name === 'Maintenance');
      if (!transportDepartment || !maintenanceDepartment) {
        throw new Error('Transportation or Maintenance department not found');
      }

      setTransportDepartmentId(String(transportDepartment._id));
      setMaintenanceDepartmentId(String(maintenanceDepartment._id));
    } catch (error) {
      console.error('Error fetching facility departments', error);
      sendToast("error", error instanceof Error ? error.message : "Error fetching facility departments");
    }
  }

  useEffect(() => {
    fetchFacilityDepartments();
  }, [props.startDate, props.endDate]);

  return (
    <div>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <div>
              {props.startDate && props.endDate ? <TopRequesters startDate={props.startDate} endDate={props.endDate} showOnlyTopFive={true} segmentHeader="Requests" height="250px" /> : <></>}
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div>
              {props.startDate && props.endDate ? <TopMissedMeals startDate={props.startDate} endDate={props.endDate} showOnlyTopFive={true} height="250px" /> : <></>}
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div>
              {props.startDate && props.endDate ? <TopCallRecipients startDate={props.startDate} endDate={props.endDate} showOnlyTopFive={true} height="250px" /> : <></>}
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div>
              {props.startDate && props.endDate ? <TopMessages startDate={props.startDate} endDate={props.endDate} showOnlyTopFive={true} height="250px" /> : <></>}
            </div>
          </Grid.Column>
        </Grid.Row>

        {/* Row 2 */}
        <Grid.Row>
          <Grid.Column width={4}>
            <div>
              {props.startDate && props.endDate && transportDepartmentId ? <TopRequesters startDate={props.startDate} endDate={props.endDate} departmentId={transportDepartmentId} showOnlyTopFive={true} segmentHeader="Transportation" height="250px" /> : <></>}
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div>
              {props.startDate && props.endDate && maintenanceDepartmentId ? <TopRequesters startDate={props.startDate} endDate={props.endDate} departmentId={maintenanceDepartmentId} showOnlyTopFive={true} segmentHeader="Maintenance" height="250px" /> : <></>}
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div>
              {props.startDate && props.endDate ? <TopStaffClosingRequests startDate={props.startDate} endDate={props.endDate} showOnlyTopFive={true} height="250px" /> : <></>}
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div>
              {props.startDate && props.endDate ? <TopVisitors startDate={props.startDate} endDate={props.endDate} showOnlyTopFive={true} height="250px" /> : <></>}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default ReportsTopFive;
