import moment from "moment-timezone";
import React, { FC, useEffect, useState } from "react";
import { Dimmer, Dropdown, DropdownProps, Grid, Image, Input, Loader, Message, Pagination, Table } from "semantic-ui-react";
import PrintExportIcons from "../PrintExportIcons";
import { Registrant, attendanceExportType } from "../../types";
import { sortByKey } from "../../util/sortData";

export interface RowData {
    [key: string]: any; // todo using any as of now as using React.Node for icons is causing to many type problems which do not make sense
}

interface TableProps {
    data: RowData[];
    loading?: boolean;
    headers: string[];
    rowKeys: string[];
    formatKeys?: string[];
    facilityTimezone: string;
    formatString: string;
    clickableKeys?: string[];
    pageNo?: number;
    setPageNo?: React.Dispatch<React.SetStateAction<number>>;
    totalDataLength?: number;
    isServerPagination?: boolean;
    clickHandler?: (header: string, rowData: RowData) => void;
    allowSearch?: boolean;
    searchkey?: string[];
    searchBoxPlaceholder?: string;
    allowExport?: boolean;
    exportFileName?: string;
    allowPrint?: boolean;
    printHandler?: (results: RowData[]) => void;
    printLoader?: boolean;
    setHeight?: string;
    selectResident?: boolean;
    residents?: Registrant[];
    selectedResident?: string[];
    handleSelectDropdown?: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
    headerClickHandler?: (header: string) => void;
    dropdownKeys?: string[]; // An array of keys which should display as dropdowns
    dropdownOptions?: { [key: string]: { text: string; value: string; tags?: string }[] }; // The options for each dropdown key
    onDropdownChange?: (value: string | number | string[], notepadId: string) => void; // Handler to capture dropdown changes
    iconHeaders?: string[]; // An array of headers which should display as icons
    iconsMap?: { [header: string]: string };
    itemsPerPage?: number;
    filter?: string[];
    refreshCurrPage?: boolean;
    multiSelectDropdownKeys?: string[];
    formattedExportData?: attendanceExportType[] | Array<RowData>;
    exportEmptyData?: boolean;
    overflowX?: 'auto' | 'hidden' | 'scroll' | 'visible' | 'initial' | 'inherit';
    hideTableMessage?: { header: string; content: string; } | undefined;
    headerBackgroundColor?: string;
    highlightAlternateRows?: boolean;
}

const CustomTable: FC<TableProps> = ({
    data,
    loading,
    headers,
    rowKeys,
    formatKeys = [],
    facilityTimezone,
    pageNo,
    setPageNo,
    totalDataLength,
    isServerPagination,
    formatString,
    clickableKeys = [],
    clickHandler,
    allowSearch = false,
    searchkey = [],
    searchBoxPlaceholder = 'Search...',
    allowExport = false,
    exportFileName = '',
    allowPrint = false,
    printHandler = () => { },
    printLoader = false,
    setHeight,
    selectResident =  false,
    residents = [],
    selectedResident = [],
    handleSelectDropdown = () => {},
    headerClickHandler,
    dropdownKeys,
    dropdownOptions,
    onDropdownChange,
    iconHeaders = [],
    iconsMap, 
    itemsPerPage = 20,
    filter = [],
    refreshCurrPage = false, 
    multiSelectDropdownKeys = [],
    formattedExportData = [],
    exportEmptyData = false,
    overflowX = 'auto',
    hideTableMessage, 
    headerBackgroundColor,
    highlightAlternateRows
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages =
        isServerPagination && totalDataLength
            ? Math.ceil(totalDataLength / itemsPerPage)
            : Math.ceil(data.length / itemsPerPage);
    const indexOfLastItem = isServerPagination && pageNo ? pageNo * itemsPerPage : currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = isServerPagination ? data : data && data.slice(indexOfFirstItem, indexOfLastItem);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(currentData);
    const [totPages, setTotPages] = useState(totalPages);
    const [exportData, setExportData] = useState<RowData[]>([]);

    useEffect(() => {
        refreshTable();
    }, [data, currentPage])

    useEffect(() => {
        if (!isServerPagination && refreshCurrPage)
            setCurrentPage(1); // conditionally set the page number to 1 if any change is made into filters or table data from the parent so that table is not empty
    }, [filter, data])

    const refreshTable = (searchTerm = '') => {
        const filteredData = (searchTerm && (!!searchkey.length && data.filter(item =>
            searchkey.some(key => 
                item[key] && String(item[key]).toLowerCase().includes(searchTerm.toLowerCase())
            )
        ))) || data; 
        // if formatted export data is present then set it else set filtered data which is the data that is being displayed  
        if (exportEmptyData) { // as for attendance table only when there are attendees for any event then only export data else export empty data
            setExportData([]);
        } else {
            setExportData(formattedExportData.length ? formattedExportData : filteredData);
        }   
        const indexOfLastItem = isServerPagination && pageNo ? pageNo * itemsPerPage : currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setTotPages(Math.ceil(filteredData.length / itemsPerPage));
        const updatedCurrentData = isServerPagination ? filteredData : filteredData && filteredData.slice(indexOfFirstItem, indexOfLastItem);
        setSearchResults(updatedCurrentData);
    }

    function formatTime(date: number | string) {
        if (date && date !== '-')
            // if date is not null or undefined "-" is set in parent component
            return moment.tz(date, facilityTimezone).format(formatString);
        else return '-';
    }
    const handleSearchChange = (e) => {
        const { value = '' }: { value: string } = e.target;
        if (value !== searchTerm) setCurrentPage(1)
        setSearchTerm(value);
        refreshTable(value);
    };
    const filteredRowKeys = rowKeys.filter((key) => key !== '_id');
    return (
        <div style={{ height: (setHeight ? setHeight : '400px'), overflowY: 'auto', overflowX: overflowX }}>
            <Dimmer inverted active={loading}>
                <Loader active={loading} />
            </Dimmer>
            <Grid columns="equal">
                <Grid.Column>
                    {allowSearch && <Input
                        icon="search"
                        placeholder={searchBoxPlaceholder}
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />}
                    {
                        selectResident &&
                        <Dropdown
                            style={{ marginLeft: '5px' }}
                            placeholder="Select Residents"
                            multiple
                            search
                            selection
                            options={residents.map((resident) => ({
                                key: resident._id,
                                value: resident._id,
                                text: `${resident.FirstName || ''} ${resident.LastName || ''}`,
                            })).sort((a, b) => a.text.localeCompare(b.text))}
                            onChange={handleSelectDropdown}
                            value={selectedResident}
                        />
                    }
                </Grid.Column>
                <Grid.Column >
                    <div style={{ float: 'right' }}>
                        {allowExport && <PrintExportIcons exportData={exportData} exportFileName={exportFileName} onPrintClick={() => printHandler(exportData)} printLoading={printLoader} disablePrintButton={!allowPrint} />}
                    </div>
                </Grid.Column>

            </Grid>
            {hideTableMessage ? (
                <Message header={hideTableMessage.header} content={hideTableMessage.content} />
            ) : (
                <Table size="large" style={{ position: 'relative' }}>
                    <Table.Header style={{ backgroundColor: headerBackgroundColor ? headerBackgroundColor : "" }}>
                        <Table.Row>
                            {headers.map((header, i) => (
                                <Table.HeaderCell
                                    style={{ cursor: 'pointer', width: header === 'Date' ? '12%' : '' }}
                                    key={i}
                                    textAlign="center"
                                    onClick={() => {
                                        if (headerClickHandler) {
                                            headerClickHandler(header);
                                        }
                                    }}
                                >
                                    {header}
                                </Table.HeaderCell>
                            ))}
                            {iconHeaders.map((iconHeader, i) => (
                                <Table.HeaderCell
                                    style={{ cursor: 'pointer' }}
                                    key={i}
                                    textAlign="center"
                                    onClick={() => {
                                        if (headerClickHandler) {
                                            headerClickHandler(iconHeader);
                                        }
                                    }}
                                >
                                    <Image
                                        style={{ margin: 'auto' }}
                                        src={iconsMap ? `${process.env.PUBLIC_URL}${iconsMap[iconHeader]}` : ''}
                                    />
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {searchResults && searchResults.length ? (
                            searchResults.map((row, i) => (
                                <Table.Row key={i} style={{backgroundColor: highlightAlternateRows && i % 2 === 0 ? 'rgba(209, 236, 255, 0.5)' : ''}}>
                                    {filteredRowKeys.map((key, j) => (
                                        <Table.Cell
                                            key={j}
                                            textAlign="center"
                                            style={
                                                clickableKeys.includes(key)
                                                    ? { color: 'blue', textDecoration: 'underline', cursor: 'pointer' }
                                                    : {}
                                            }
                                            onClick={() => {
                                                clickableKeys.includes(key) && clickHandler && clickHandler(key, row);
                                            }}
                                        >
                                            {React.isValidElement(row[key]) ? (
                                                row[key]
                                            ) : dropdownKeys && dropdownKeys.includes(key) ? (
                                                <div style={{ width: '300px' }}>
                                                    <Dropdown
                                                        style={{ width: '100%' }}
                                                        scrolling
                                                        wrapSelection
                                                        placeholder="Select Option"
                                                        selection
                                                        multiple={
                                                            multiSelectDropdownKeys &&
                                                            multiSelectDropdownKeys.includes(key)
                                                        }
                                                        options={
                                                            (dropdownOptions && sortByKey(dropdownOptions[key])) || []
                                                        }
                                                        value={
                                                            multiSelectDropdownKeys.includes(key)
                                                                ? (row[key] as string[])
                                                                : (row[key] as string)
                                                        }
                                                        search={(options, query) => {
                                                            return options.filter((option) => {
                                                                // Search by text which is service name
                                                                if (
                                                                    typeof option.text === 'string' &&
                                                                    option.text
                                                                        .toLowerCase()
                                                                        .includes(query.toLowerCase())
                                                                ) {
                                                                    return true;
                                                                }
                                                                // Search by tags key in service
                                                                if (
                                                                    option.tags &&
                                                                    option.tags
                                                                        .toLowerCase()
                                                                        .includes(query.toLowerCase())
                                                                ) {
                                                                    return true;
                                                                }
                                                                return false;
                                                            });
                                                        }}
                                                        clearable
                                                        disabled={row.SyncStatus === 'merged' || row.isKioskEvent}
                                                        onChange={(e, data) => {
                                                            if (onDropdownChange) {
                                                                if (multiSelectDropdownKeys.includes(key)) {
                                                                    // Handle multi-select value
                                                                    let values = Array.isArray(data.value)
                                                                        ? data.value
                                                                        : [];
                                                                    row[key] = values;
                                                                    // @ts-ignore
                                                                    onDropdownChange(values, String(row._id));
                                                                } else {
                                                                    // Existing single select logic
                                                                    let value =
                                                                        typeof data.value === 'string'
                                                                            ? data.value
                                                                            : '';
                                                                    row[key] = value;
                                                                    onDropdownChange(value, String(row._id));
                                                                }
                                                            } else {
                                                                console.error('onDropdownChange is not defined');
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            ) : formatKeys.includes(key) && row[key] ? (
                                                formatTime(row[key] as string | number)
                                            ) : (
                                                row[key]
                                            )}
                                        </Table.Cell>
                                    ))}
                                </Table.Row>
                            ))
                        ) : (
                            <></>
                        )}
                    </Table.Body>
                </Table>
            )}
            {searchResults && searchResults.length ? (
                <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center' }}>
                    <Pagination
                        totalPages={isServerPagination ? totalPages : totPages}
                        activePage={isServerPagination ? pageNo : currentPage}
                        onPageChange={(e, { activePage }) => {
                            if (isServerPagination && setPageNo) {
                                setPageNo(Number(activePage));
                            } else setCurrentPage(Number(activePage));
                        }}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={1}
                        boundaryRange={0}
                        size="mini"
                    />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default CustomTable;
