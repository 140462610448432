import React, { FC, useEffect, useState } from "react";
import { List, Header, Segment } from "semantic-ui-react";
import moment from "moment";
import { sendToast } from "../../util";
import { ListResidentMeals } from "../../services/ResidentsMeals";

interface Props {
    startDate: Date;
    endDate: Date;
    showOnlyTopFive?: boolean;
    height?: string; 
}

interface MissedMealsRecord {
    residentName: string;
    missedMeals: number;
}

const TopMissedMeals: FC<Props> = (props) => {
    const [missedMealsRecord, setMissedMealsRecord] = useState<MissedMealsRecord[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    // Fetch and process missed meals data
    const fetchMissedMealsData = async () => {
        try {
            setIsLoading(true);

            // Format the dates for the API request
            const formattedStartDate = moment(props.startDate).format("YYYY-MM-DD");
            const formattedEndDate = moment(props.endDate).format("YYYY-MM-DD");

            // Fetch resident meals data
            const residentMeals = await ListResidentMeals(
                formattedStartDate,
                formattedEndDate,
                true // includeResidentsName
            );

            // Calculate missed meals using reduce
            const mealsCount = residentMeals.reduce((acc, meal) => {
                const missedCount =
                    (!meal.hadBreakfast ? 1 : 0) +
                    (!meal.hadLunch ? 1 : 0) +
                    (!meal.hadDinner ? 1 : 0);

                if (!acc[meal.residentId]) {
                    acc[meal.residentId] = {
                        residentName: meal.residentName,
                        missedMeals: missedCount,
                    };
                } else {
                    acc[meal.residentId].missedMeals += missedCount;
                }

                return acc;
            }, {});

            const sortedMissedMealsRecord: any[] = Object.values(mealsCount)
                .sort((a: any, b: any) => b.missedMeals - a.missedMeals)
                .slice(0, props.showOnlyTopFive ? 5 : Object.values(mealsCount).length);

            setMissedMealsRecord(sortedMissedMealsRecord);
        } catch (error) {
            sendToast("error", "Failed to fetch missed meals data");
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        fetchMissedMealsData();
    }, [props.startDate, props.endDate]);

    const defaultStyle = { width: "300px" }
    const segmentStyle = props.height ? { ...defaultStyle, height: props.height } : defaultStyle
    return (
        <Segment style={segmentStyle} loading={isLoading}>
            <Header as="h4" style={{ marginBottom: "10px" }}>
                Top Missed Meals
            </Header>
            <List divided verticalAlign="middle">
                {missedMealsRecord.map((record, index) => (
                    <List.Item key={index}>
                        <List.Content floated="right">
                            <span>{record.missedMeals}</span>
                        </List.Content>
                        <List.Content>
                            <span
                                style={{
                                    display: "inline-block",
                                    padding: "4px 8px",
                                    borderRadius: "4px",
                                }}
                            >
                                {record.residentName}
                            </span>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </Segment>
    );
};

export default TopMissedMeals;
