import { Message } from "../types/Analytics";
import { fetchAllPaginatedData } from "../util";
import API from "./API";

export const listAllMessagesForFilter = async (filter): Promise<Message[]> => {
    try {
        const apiCall = async (params: any) => { return await API.lambdaPost('/messages/list', params); };
        const result = await fetchAllPaginatedData(apiCall, { filter });
        return result;
    } catch (error) {
        console.error("Error while fetching messages", error);
        throw error;
    }
};