import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { toast } from 'react-toastify'
import RegistrantEditor from '../RegistrantEditor'
import { Unit, Registrant, User, RegistrantRelative } from '../../../../types'
import { fetchAllUnits } from '../../../../services/Units'

import { fetchOneRegistrant, updateRegistrant, deleteRegistrant, fetchPaginatedRegistrantA4HContacts, enableInboundCalling } from '../../../../services/Registrants'
import { fetchStaffRoleUsers } from '../../../../services/Users'
import { Promise } from 'bluebird';
import { updateMember } from '../../../../services/Groups';

interface State {
    units: Unit[]
    isFetching: boolean
    hasError: boolean
    error: string | null
    isSaving: boolean
    registrant: Registrant | null
    staffUsers: (User | { _id: string; FirstName: string })[]
}
interface Props {
    id: string
    activeIndex?: number
    feedRefreshKey?: number
}

class EditRegistrants extends React.Component<Props, State> {
    id: string
    constructor(props : Props) {
        super(props)
        this.id = props.id
        this.state = {
            units: [],
            isFetching: false,
            hasError: false,
            error: null,
            isSaving: false,
            registrant: null,
            staffUsers: []
        }
    }

    async componentDidMount() {
        try {
            this.setState({
                isFetching: true,
                hasError: false,
                error: null,
            })
            const [units, registrant, staffUsers] = await Promise.all([fetchAllUnits(), fetchOneRegistrant(this.id), fetchStaffRoleUsers()])

            // If the value of PrimaryCaregiver in DB is 'Other', map the value of PrimaryCaregiver to object format to make it compatible with
            // existing code and semantic-ui dropdown element
            if (registrant.PrimaryCaregiver === 'Other') {
                registrant.PrimaryCaregiver = { _id: '1', FirstName: 'Other' };
            } else if (registrant.PrimaryCaregiver && staffUsers.length) {
                // If it holds a value other than 'Other', map it to the object of the caregiver (User), to make it compatible with
                // exissting code and semantic-ui dropdown element
                registrant.PrimaryCaregiver = staffUsers.filter(user => {
                    return user._id === registrant.PrimaryCaregiver;
                })[0];
            }

            this.setState({
                isFetching: false,
                hasError: false,
                units,
                registrant,
                staffUsers
            })
        } catch (e) {
            this.setState({
                isFetching: false,
                hasError: true,
                error: e.message || 'Could not fetch registrant, units or .',
            })
        }
    }

    goBack() {
        // currently the detail view is positioned next to the list view that is why there is not need for go back
        // this.props.history.goBack()
    }

    async handleSave(registrant: Partial<Registrant>, unit: Unit | null) {
        const moveSuccessMsg = 'The resident has been moved, along with their contacts'
        const residentUpdateMsg = 'The resident has been updated'
        const srcRoomId = registrant && registrant.Unit && registrant.Unit._id
        const destRoomId = (unit && unit._id) || ''
        const isMove = (srcRoomId !== destRoomId) && (typeof srcRoomId === 'string' && typeof destRoomId === 'string')
        
        if (!registrant._id) return
        this.setState({
            isSaving: true,
            hasError: false,
            error: null,
        })
        try {

            //handle group update
            if (
                (this.state.registrant && this.state.registrant.Groups && this.state.registrant.Groups.length) || 
                (registrant.Groups && registrant.Groups.length)
            ) {
                const addedGroups = (registrant.Groups && registrant.Groups.filter(
                    (group) =>
                        this.state.registrant &&
                        this.state.registrant.Groups &&
                        !this.state.registrant.Groups.includes(group)
                )) || [];

                const removedGroups = (this.state.registrant && this.state.registrant.Groups && this.state.registrant.Groups.filter(
                    (group) =>
                        registrant.Groups &&
                        !registrant.Groups.includes(group)
                )) || [];

                if (addedGroups.length) {
                    await Promise.all(
                        addedGroups.map(function (group) {
                            return updateMember({
                                _id: group,
                                residentId: String(registrant._id),
                            });
                        })
                    );
                }

                if (removedGroups.length) {
                    await Promise.all(
                        removedGroups.map(function (group) {
                            return updateMember({
                                _id: String(group),
                                residentId: String(registrant._id),
                                remove: true,
                            });
                        })
                    );
                }
            }
            
            await updateRegistrant(registrant._id, {...registrant, Groups: undefined}, unit)
            // fetch resident contacts
            const pageSize = 100;
            let pageNo = 1;
            const registrantContacts: RegistrantRelative[] = [];
            let contactsLength: number;
            do {
                const paginatedContacts = await fetchPaginatedRegistrantA4HContacts(registrant._id, pageNo, pageSize, 'staff')
                contactsLength = paginatedContacts && paginatedContacts.length || 0;
                pageNo += 1;
                registrantContacts.push(...paginatedContacts);
            } while (contactsLength === pageSize);

            // enable inbound calling for each contact
            let inboundCallFailedForFewContacts = false;
            await Promise.map(registrantContacts, async(contact) => {
                try {
                    return registrant._id && await enableInboundCalling(registrant._id, contact.contacts.contactId);
                } catch (error) {
                    inboundCallFailedForFewContacts = true;
                }
            }, 
            {
                concurrency: 5
            })
            if (isMove) { // if resident is moved
                toast.success(inboundCallFailedForFewContacts ? moveSuccessMsg + ', but could not enable inbound calling for all contacts' : moveSuccessMsg, {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            } else { // if resident is updated
                toast.success(residentUpdateMsg, {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            }
            this.setState({
                isSaving: false,
                hasError: false,
                error: null,
            })
            this.goBack()
        } catch (e) {
            this.setState({
                isSaving: false,
                hasError: true,
                error: e.message || 'Could not save Resident.',
            })
            toast.warn('Something went wrong, this page will auto-reload in 5seconds', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
            setTimeout(()=>{
                window.location.reload()
            }, 5000)
        }
    }

    async handleDelete(registrantId: string) {
        this.setState({
            isSaving: true,
            hasError: false,
            error: null,
        })
        try {
            await deleteRegistrant(registrantId)
            this.setState({
                isSaving: false,
                hasError: false,
            })
            toast.success('The resident has been deleted, along with their contacts and notifications', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
            this.goBack()
        } catch (e) {
            this.setState({
                isSaving: false,
                hasError: true,
                error: e.message,
            })
            toast.warn('Something went wrong', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        }
    }

    render() {
        return (
            <div className="EditRegistrant">
                <RegistrantEditor
                    cancel={this.goBack.bind(this)}
                    {...this.state}
                    onSave={this.handleSave.bind(this)}
                    onDelete={this.handleDelete.bind(this)}
                    activeIndex={this.props.activeIndex}
                    feedRefreshKey={this.props.feedRefreshKey}
                />
            </div>
        )
    }
}

export default EditRegistrants
