import API from './API'
import { CustomAppInfoType, Facility, FacilityConfigurationsTypes, FacilityFeatureType, Identifiable } from '../types'
import { LocationType } from '../components/ManageFacility/LocationTable'
import { fetchAllPaginatedData } from '../util'

export async function fetchFacilities(): Promise<Facility[]> {
    const res = await API.lambdaGet('/facilities/list')
    res.Result = res.Result.filter((f: Facility) => f.IsActive === 1)
    return res.Result as Facility[]
}

export async function fetchAllActiveFacilities(): Promise<Facility[]> {
    const params = {
        IsActive: 1
    }
    const fetchActiveFacilities = async (params) => { return await API.lambdaPost('/facilities/list', params); };
    const facilities = await fetchAllPaginatedData(fetchActiveFacilities, params);
    return facilities as Facility[];
}

export async function fetchA4hFacilities(): Promise<Facility[]> {
    const res = await API.lambdaGet('/facilities/listA4h');
    console.log('res', res);
    const facilities = res.Profiles.map(f => ({Name: f.ProfileName, id: f.ProfileId}));
    return facilities;
}

export async function fetchOneFacility(id: string): Promise<Facility> {
    const res = await API.lambdaGet(`/facilities/get?_id=${id}`)
    return res as Facility
}

export async function createFacility(facility: Partial<Facility>): Promise<Facility> {
    const id = await API.lambdaPost('/facilities/add', facility)
    const created = await fetchOneFacility(id)
    return created
}

export async function updateFacility(facility: Partial<Facility> & Identifiable): Promise<Facility> {
    if (!facility._id) throw new Error('Updated facility object must contain _id field')
    await API.lambdaPost('/facilities/update', facility)
    const updated = await fetchOneFacility(facility._id)
    return updated
}

export async function deleteFacility(facilityId: String): Promise<any> {
    await API.lambdaPost('/facilities/update', {
        _id: facilityId,
        IsActive: 0,
    })

}

export async function fetchFacilityRooms(): Promise<any> {
    const { Rooms } = await API.lambdaGet(`/facilities/rooms/list`);
    return Rooms;
}

export async function fetchResidentRooms(): Promise<any> {
    const Rooms = await API.lambdaGet(`/facilities/rooms/listResidentRooms`);
    return Rooms;
}

export async function fetchAllResidentRooms(): Promise<any> {
    const Rooms = await API.lambdaGet(`/facilities/rooms/listAllResidentRooms`);
    return Rooms;
}

export async function fetchAllRooms(): Promise<any> {
    const Rooms = await API.lambdaGet(`/facilities/rooms/listRooms`);
    return Rooms;
}

export async function fetchResidentRoomsForFacilityId(facilityId): Promise<any> {
    //used by sysadmin
    const Rooms = await API.lambdaPost(`/facilities/rooms/listResidentRooms`, { facilityId });
    return Rooms;
}

export async function fetchOneFeaturedFacility(facilityId: string): Promise<any>{
    const featuredFacility = await API.lambdaGet(`/webrtc/enabled-facilities/getOne`,{Facility:facilityId});
    return featuredFacility;
}

export const upsertFacilityLocations = async (data: object, type: "activity" | "menu") => {
    try {
        const res = await API.lambdaPost(`/facilities/location/upsert`, { ...data, type });
        return res;
    } catch (error) {
        console.error("upsertFacilityLocations error", error);
        throw new Error(error instanceof Error ? error.message : "Failed to upsert facility location");
    }

};

export const listFacilityLocations = async (): Promise<{
    activityLocations: LocationType[];
    menuLocations: LocationType[];
}> => {
    try {
        const res = await API.lambdaPost(`/facilities/location/list`, {});
        return res;
    } catch (error) {
        console.error("upsertFacilityLocations error", error);
        throw new Error(error instanceof Error ? error.message : "Failed to upsert facility location");
    }

};

export async function addCustomAppInfo(customAppInfo: CustomAppInfoType, facilityId: string): Promise<string> {
    try {
        const id = await API.lambdaPost('/facilities/custom-app-info/create', { ...customAppInfo, Facility: facilityId });
        return id;
    } catch (error) {
        console.error("addCustomAppInfo error", error);
        throw new Error(error instanceof Error ? error.message : "Failed to add custom app info");
    }
}

export async function updateCustomAppInfo(customAppInfo: CustomAppInfoType, facilityId: string): Promise<string> {
    try {
        const id = await API.lambdaPost('/facilities/custom-app-info/update', { ...customAppInfo, Facility: facilityId });
        return id;
    } catch (error) {
        console.error("updateCustomAppInfo error", error);
        throw new Error(error instanceof Error ? error.message : "Failed to save custom app info");
    }
}

export async function addFacilityConfigurations(configurations: FacilityConfigurationsTypes, facilityId: string): Promise<string> {
    try {
        const id = await API.lambdaPost('/facilities/configure-features/create', { configurations, Facility: facilityId });
        return id;
    } catch (error) {
        console.error("addFacilityConfigurations error", error);
        throw new Error(error instanceof Error ? error.message : "Failed to add facility configurations");
    }
}

export async function updateFacilityConfigurations(params: FacilityFeatureType, facilityId: string): Promise<string> {
    try {
        const id = await API.lambdaPost('/facilities/configure-features/update', { ...params, Facility: facilityId });
        return id;
    } catch (error) {
        console.error("updateFacilityConfigurations error", error);
        throw new Error(error instanceof Error ? error.message : "Failed to update facility configurations");
    }
}

export async function updateFacilityDefaultMessage(facilityId:string, defaultMessage: string) {
    try {
        const updatePayload = {
            _id: facilityId,
            DefaultMessage: defaultMessage
        }; 

        const updateResponse = await API.lambdaPost('/facilities/update', updatePayload);
        return updateResponse;
    } catch (error) {
        console.error("updateFacilityDefaultMessage error", error);
        throw new Error(error instanceof Error ? error.message : "Failed to update facility default message");
    }
}; 

export async function updateFacilityAutomateMatchAnnouncement(facilityId:string, automateMatchAnnouncement: boolean) {
    try {
        const updatePayload = {
            _id: facilityId,
            AutomateMatchAnnouncement: automateMatchAnnouncement
        }; 

        const updateResponse = await API.lambdaPost('/facilities/update', updatePayload);
        return updateResponse;
    } catch (error) {
        console.error("updateFacilityAutomateMatchAnnouncement error", error);
        throw new Error(error instanceof Error ? error.message : "Failed to update facility automate match announcement");
    }
};
