import API from './API'

import { DailyActivity } from '../types'
import { fetchAllPaginatedData } from '../util/index';

export async function newActivity(dailyActivity: DailyActivity): Promise<string> {
    const response = await API.lambdaPost('/dailyactivities/add', dailyActivity)
    return response
}

export async function Activities(filters = {}): Promise<any> {
    const params = {
        Filter: { ...filters },
    }
    console.log('params', params)
    const response = await API.lambdaPost('/dailyactivities/list', params)
    return response
}

export async function fetchAllActivities(filter = {}, sort = [{dateTimeString: 'desc'}, { timestamp: "desc" }]): Promise<any> {
    if ((Object.keys(filter)).length === 0) throw new Error('Filter is required');
    const activityApiCall = async (params) => { return await API.lambdaPost('/dailyactivities/list', params); };
    const params = {
        Filter: filter,
    }
    const activities = await fetchAllPaginatedData(activityApiCall, params, sort);
    return activities;
}

export async function deleteActivity(dataobject: object): Promise<any> {
    const response = await API.lambdaPost('/dailyactivities/delete', dataobject);
    return response
}

export async function updateActivity(dailyActivity: Partial<DailyActivity>): Promise<number> {
    const response = await API.lambdaPut('/dailyactivities/update', dailyActivity)
    return response
}

export async function listActivityAttendees(_id, isSvcInstanceId = false): Promise<any> {
    const params = {};
    if (isSvcInstanceId) {
        params['svcInstanceId'] = _id;
    } else {
        params['activityId'] = _id;
    }
    const response = await API.lambdaPost('/dailyactivities/attendees/list', params)
    return response
}

export async function listAttendeesDataForMultipleServiceInstances(svcInstanceIds: string[]) {
    const filter = {
        svcInstanceIds
    };
    const response = await API.lambdaPost('/dailyactivities/attendees/list', filter);
    return response;
}

export async function listActivityAttendeesForCustomFilter(params) {
    const response = await API.lambdaPost('/dailyactivities/attendees/list', params)
    return response
}

export async function listActivityAttendeesForMultipleActivityIds(activityIds: string[]): Promise<any> {
    const params = {
        activityIds
    }
    const attendeesApiCall = async (params) => { return await API.lambdaPost('/dailyactivities/attendees/list', params); };
    const attendees = await fetchAllPaginatedData(attendeesApiCall, params);
    return attendees;
}

export async function updateActivityAttendee(id, status, activityId: string) {
    const params = {
        attendeeObjId: id,
        status,
        activityId
    }
    const response = await API.lambdaPut('/dailyactivities/attendees/update', params)
    return response
}

export async function updateActivityAttendeeParticipation(id, status, participation, activityId: string) {
    const params = {
        attendeeObjId: id,
        status,
        participation,
        activityId
    }
    const response = await API.lambdaPut('/dailyactivities/attendees/update', params)
    return response
}

export async function updateActivityAttendeeDeclineReason(id, status, declineReason, activityId: string) {
    console.log(activityId)
    const params = {
        attendeeObjId: id,
        status,
        declineReason,
        activityId
    }
    const response = await API.lambdaPut('/dailyactivities/attendees/update', params)
    return response
}

export async function createActivityAttendee (activityAttendeeObj) {
    return await API.lambdaPost('/dailyactivities/attendees/add', activityAttendeeObj)
}

export async function deleteActivityAttendee(id: string, activityId: string) {
    const response = await API.lambdaPut('/dailyactivities/attendees/delete', { _id: id, activityId });
    return response;
}