import React, { useEffect, useState } from 'react';
import ActivityAnalysis from '../ActivityAnalysis';
import ReportsResidentInteractions from '../ReportsResidentInteractions';
import moment from 'moment';
import TopRequesters from '../TopRequesters';
import { Grid, Header, Icon, Button, Checkbox, Popup, Modal } from 'semantic-ui-react';
import CustomDatePicker, { DateRange } from '../CustomDatePicker';
import { fetchOneFacility, updateFacilityAutomateMatchAnnouncement, updateFacilityDefaultMessage } from '../../services/Facilities';
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
import { sendToast } from '../../util';
import HighlightTextarea from '../ActivityAnalysis/HighlightTextarea';

const ResidentLandingPage = () => {
    const startOfDay: Date = moment().startOf('day').toDate();
    const endOfDay: Date = moment().endOf('day').toDate();
    const [dateRange, setSelectedDateRange] = useState<DateRange>({
        start: startOfDay,
        end: endOfDay
    });
    const [automateCheckbox, setAutomateCheckbox] = useState<boolean>(false);
    const [automateUpdating, setAutomateUpdating] = useState<boolean>(false);
    const [handelAnnouncementLoading, setHandelAnnouncementLoading] = useState<boolean>(false);
    const [defaultMessage, setDefaultMessage] = useState<string>("Based on your preferences, we think you will love our upcoming event, {name} at {time} in {location}.");
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // State for controlling modal visibility

    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });
    const facility = profile && profile.Facility || "";

    useEffect(() => {
        (async () => {
            const facilityObj = await fetchOneFacility(facility);
            if (facilityObj && facilityObj.DefaultMessage) {
                setDefaultMessage(facilityObj.DefaultMessage);
                setAutomateCheckbox(facilityObj.AutomateMatchAnnouncement || false);
            }
        })();
    }, [facility]);

    const handleAutomateCheckBoxUpdate = async () => {
        try {
            setAutomateUpdating(true);
            setAutomateCheckbox(prev => !prev);
            await updateFacilityAutomateMatchAnnouncement(facility, !automateCheckbox);
            sendToast("success", "Automate announcement checkbox updated successfully!");
        } catch (error) {
            console.error("Error updating automation checkbox:", error);
            sendToast("error", error instanceof Error ? error.message : "Failed to update automation checkbox");
        } finally {
            setAutomateUpdating(false);
        }
    };

    const updateDefaultMessage = async () => {
        try {
            setHandelAnnouncementLoading(true);
            await updateFacilityDefaultMessage(facility, defaultMessage);
            sendToast("success", "Default message updated successfully!");
        } catch (error) {
            console.error("Error updating default message:", error);
            sendToast("error", error instanceof Error ? error.message : "Failed to update default message");
        } finally {
            setHandelAnnouncementLoading(false);
        }
    };

    const onDateRangeChange = (start: Date, end: Date) => {
        setSelectedDateRange({
            start,
            end
        });
    };

    const openModal = () => setIsModalOpen(true);  // Open the modal
    const closeModal = () => setIsModalOpen(false); // Close the modal


    const announcementWidget = () => {
        return (
            <div style={{ marginTop: '10px' }}>
                <Header as="h4">Default Announcement Text</Header>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {/* Container for TextArea, Loader, and Dimmer */}
                    <div style={{ position: 'relative' }}>
                        <div style={{ width: "20vw" }}>
                            <HighlightTextarea text={defaultMessage} setText={(text) => setDefaultMessage(text)} />
                            <Button
                                primary
                                size="small"
                                style={{ display: "block", marginTop: "10px" }}
                                onClick={() => {
                                    updateDefaultMessage();
                                }}
                                loading={handelAnnouncementLoading}
                            >
                                Update Default Message
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Grid columns={2}>
                <Grid.Column width={12}>
                    <Header as='h4' style={{ color: "#5C5D5D", fontFamily: "lato" }}>Activity Analysis</Header>
                    <div>
                        {/* Header for Date Picker and Icons */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <CustomDatePicker dateRange={dateRange} onChange={onDateRangeChange} maxDate={new Date()} skipMaxDate={false} />
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* Announcement Icon and Text */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '15px' }}>
                                    <Icon name='announcement' color='orange' size={"big"} onClick={openModal} style={{ cursor: "pointer" }} />
                                    <div style={{ fontSize: '12px', marginTop: '5px' }}>Edit</div>
                                </div>
                                {/* Popup for Toggle */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Popup
                                        content="Turn on to automate an announcement being sent to matching residents 15 minutes prior to the event they match with."
                                        trigger={
                                            <Checkbox
                                                toggle
                                                checked={automateCheckbox} // Ensure it's unchecked
                                                onChange={() => {
                                                    handleAutomateCheckBoxUpdate();
                                                }}
                                                disabled={automateUpdating}
                                            />
                                        }
                                    />
                                    <div style={{ fontSize: '12px', marginTop: '5px' }}>Auto</div>
                                </div>
                            </div>
                        </div>
                        {/* Activity Analysis */}
                        <ActivityAnalysis dateRange={dateRange} defaultMessage={defaultMessage} />
                    </div>
                </Grid.Column>
                <Grid.Column width={4}>
                    <TopRequesters startDate={dateRange.start} endDate={dateRange.end} />
                </Grid.Column>
            </Grid>
            <Header as='h4' style={{ color: "#5C5D5D", fontFamily: "lato" }}>Resident Interactions</Header>
            <ReportsResidentInteractions startDate={dateRange.start} endDate={dateRange.end} renderTableInNormalDiv headerBackgroundColor='#FBFBFB' highlightAlternateRows />

            {/* Modal for Editing Announcement */}
            <Modal open={isModalOpen} onClose={closeModal} size='small' closeIcon={true}>
                <Modal.Header>Edit Default Announcement Text</Modal.Header>
                <Modal.Content>
                    {announcementWidget()}
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default ResidentLandingPage;
