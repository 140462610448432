import React, { FC, useEffect, useState } from "react";
import { List, Header, Segment, Loader, Dimmer } from "semantic-ui-react";
import { fetchAllRequestsForFilter } from "../../services/Requests";
import moment from "moment";
import { sendToast } from "../../util";

interface Props {
    startDate: Date | undefined;
    endDate: Date | undefined;
    showOnlyTopFive?: boolean;
    segmentHeader?: string;
    departmentId?: string;
    height?: string;
}

interface Requester {
    residentName: string;
    requestsCount: number;
}

const TopRequesters: FC<Props> = (props) => {
    const [requesters, setRequesters] = useState<Requester[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        try {
            setIsLoading(true);

            const formattedStartDate = moment(props.startDate).format("YYYY-MM-DDTHH:mm:ss");
            const formattedEndDate = moment(props.endDate).format("YYYY-MM-DDTHH:mm:ss");
            const requestsFilter = {
                Status: ["Open", "Accepted", "Closed"],
                StartDateTime: formattedStartDate,
                EndDateTime: formattedEndDate,
                Department: props.departmentId,
            };

            const allRequests = await fetchAllRequestsForFilter(requestsFilter);

            const requesterMap = allRequests.reduce((acc, request) => {
                const registrantId = request.Registrant;
                const registrantName = `${request.Registrant_FirstName} ${request.Registrant_LastName}`;

                if (!acc[registrantId]) {
                    acc[registrantId] = { name: registrantName, count: 0 };
                }
                acc[registrantId].count += 1;

                return acc;
            }, {});

            const requestersArray = Object.values(requesterMap).map((entry: any) => ({
                residentName: entry.name,
                requestsCount: entry.count,
            }));
            const slicedArray = requestersArray.sort((a, b) => b.requestsCount - a.requestsCount).slice(0, props.showOnlyTopFive ? 5 : requestersArray.length);;
            setRequesters(slicedArray);
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error fetching requests");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            if (props.startDate && props.endDate) {
                await fetchData();
            }
        })();
    }, [props.startDate, props.endDate]);

    const defaultStyle = { width: "300px" }
    const segmentStyle = props.height ? { ...defaultStyle, height: props.height } : defaultStyle
    return (
        <Segment style={segmentStyle}>
            <Dimmer active={isLoading} inverted>
                <Loader>Loading...</Loader>
            </Dimmer>
            <Header as="h4" style={{ marginBottom: "5px", marginTop: "0" }}>
                {props.segmentHeader ? props.segmentHeader : "Top Requesters"}
            </Header>
            <List divided verticalAlign="middle">
                {requesters.map((requester, index) => (
                    <List.Item key={index}>
                        <List.Content floated="right">
                            <span>{requester.requestsCount}</span>
                        </List.Content>
                        <List.Content>
                            <span
                                style={{
                                    display: "inline-block",
                                    padding: "4px 8px",
                                    borderRadius: "4px",
                                }}
                            >
                                {requester.residentName}
                            </span>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </Segment>
    );
};

export default TopRequesters;
