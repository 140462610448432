import { KioskAnswersType } from "../types/KioskAnswers";
import API from "./API";

interface KioskAnswersResponse {
    Result: KioskAnswersType[];
    totalRecords: number;
}

export async function listKioskAnswers(startDate: number, endDate: number, page_no: number, page_size: number): Promise<KioskAnswersResponse> {
    const params = {
        startDate,
        endDate,
        page_no,
        page_size
    };

    const response = await API.lambdaPost('/kiosk/answers/list', params);
    return response;
}

export const fetchAllKioskAnswers = async (startDate: number, endDate: number): Promise<KioskAnswersType[]> => {
    try {
        let page_no = 1;
        let page_size = 100;
        let totalRecords = 0;
        let kioskAnswers: KioskAnswersType[] = [];
    
        do {
            const response = await listKioskAnswers(startDate, endDate, page_no, page_size);
            totalRecords = response.totalRecords;
            kioskAnswers = kioskAnswers.concat(response.Result);
            page_no++;
        } while (kioskAnswers.length < totalRecords);
    
        return kioskAnswers;
    } catch (error) {
        console.log('Error fetching kiosk answers', error);
        throw error;
    }
};