import React, { useRef, ChangeEvent } from 'react';
import './HighlightTextarea.less';

interface HighlightTextareaProps {
    text: string;
    setText: (text: string) => void;
}

const HighlightTextarea: React.FC<HighlightTextareaProps> = ({ text, setText }) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const highlightRef = useRef<HTMLDivElement | null>(null);

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    };

    // if the text area is scrolled then scroll the highlight div so that both are in sync.
    const handleScroll = () => {
        if (highlightRef.current && textareaRef.current) {
            highlightRef.current.scrollTop = textareaRef.current.scrollTop;
            highlightRef.current.scrollLeft = textareaRef.current.scrollLeft;
        }
    };

    // Highlight placeholders in the text
    const getHighlightedText = (input: string) => {
        const placeholderPattern = /\{(name|time|location)\}/g;
        const parts = input.split(placeholderPattern);

        return parts.map((part, index) => {
            if (['name', 'time', 'location'].includes(part)) {
                return (
                    <span key={index} className="highlight">
                        {'{'}{part}{'}'}
                    </span>
                );
            }
            return part;
        });
    };

    return (
        <div className="highlight-textarea-wrapper">
            <div
                className="highlighted-text"
                ref={highlightRef}
                aria-hidden="true"
            >
                {getHighlightedText(text)}
            </div>
            <textarea
                ref={textareaRef}
                value={text}
                onChange={handleChange}
                onScroll={handleScroll}
                className="textarea"
                placeholder="Type your message here..."
                spellCheck={false}
            />
        </div>
    );
};

export default HighlightTextarea;
