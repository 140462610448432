import API from './API'
import { Registrant, Unit, RegistrantRelative, ResidentCalendar } from '../types'
import { DietaryState } from '../types/Dietary'
import { Promise } from 'bluebird';
import { fetchAllPaginatedData } from '../util';

export async function fetchRegistrants(additionalParams?: object): Promise<Registrant[]> {
    return await fetchFacilityRegistrants(additionalParams)
}

export async function fetchPaginatedRegistrants(page_no = 1, page_size = 50, search, additionalParams?: object): Promise<Registrant[]> {
    const res = await API.lambdaPost('/registrants/list', {
        Filter: {
            IsActive: 1,
        },
        ...(additionalParams || {}),
        Search: search,
        QueryOptions: {
            page_no,
            page_size,
            sort: [{ LastName: 'desc' }],
        },
    })

    const registrants = res.Result as Registrant[]
    return registrants
}

export async function fetchPaginatedInactiveRegistrants(page_no = 1, page_size = 50, search): Promise<Registrant[]> {
    const res = await API.lambdaPost('/registrants/list', {
        Filter: {
            IsActive: 0,
        },
        Search: search,
        QueryOptions: {
            page_no,
            page_size,
            sort: [{ LastName: 'desc' }],
        },
    })

    const registrants = res.Result as Registrant[]
    return registrants
}

export const fetchAllPaginatedResidents = async (additionalParams?: object): Promise<Registrant[]> => {
    let page_no = 1;
    let registrantsArr: any = [];
    let registrants: any = [];
    const page_size = 500;
    const bulkAPICallPages: number[] = [];

    const registrantsResponse = await API.lambdaPost('/registrants/list', {
        Filter: {
            IsActive: 1,
        },
        ...(additionalParams || {}),
        QueryOptions: {
            page_no,
            page_size,
            sort: [{ LastName: 'desc' }],
        },
    })
    registrants = registrantsResponse.Result;
    if (registrants && registrants.length) {
        registrantsArr.push(...registrants);
    }

    do {
        page_no = page_no + 1;
        bulkAPICallPages.push(page_no)
    } while (registrantsResponse.TotRecords > page_size * page_no);
    await Promise.map(
        bulkAPICallPages,
        async (pageNo: number) => {
            registrants = await fetchPaginatedRegistrants(pageNo, page_size, "", additionalParams)
            if (registrants && registrants.length) {
                registrantsArr.push(...registrants);
            }
        },
        {
            concurrency: 2
        }
    )
    return registrantsArr;
}

export async function fetchPaginatedRegistrantRelatives(registrantId, page_no = 1, page_size = 6): Promise<RegistrantRelative[]> {
    const res = await API.lambdaPost('/registrants/relatives/list', {
        Filter: {
            _id: registrantId,
        },
        QueryOptions: {
            page_no,
            page_size,
            sort: [{ _id: 'asc' }]
        },
    })
    if (res.length) {
        const relatives = res as RegistrantRelative[];
        return relatives;
    } else {
        return []
    }
}

export async function fetchPaginatedRegistrantA4HContacts(registrantId, page_no = 1, page_size = 6, Type: null | string = null): Promise<RegistrantRelative[]> {
    const res = await API.lambdaPost('/registrants/a4h/list', {
        Filter: {
            _id: registrantId,
        },
        QueryOptions: {
            page_no,
            page_size,
            sort: [{ _id: 'asc' }]
        },
        Type
    })
    if (res.length) {
        const relatives = res as RegistrantRelative[];
        return relatives;
    } else {
        return []
    }
}

export async function fetchOneRegistrant(id: string): Promise<Registrant> {
    const res = await API.lambdaGet(`/registrants/get?_id=${id}`)
    return res as Registrant
}

export async function assignRegistrantToUnit(registrantId: string, unitId: string): Promise<any> {
    const res = await API.lambdaPost('/registrants/units/add', {
        Registrant: registrantId,
        Unit: unitId,
    })
    return res
}

export async function unAssignRegistrantFromUnit(registrantId: string): Promise<any> {
    const res = await API.lambdaPost('/registrants/units/add', {
        Registrant: registrantId,
    })
    return res
}

export async function createAndAssignRegistrant(reg: Partial<Registrant>, unitId: string | null): Promise<Registrant> {
    const createdId = await API.lambdaPost('/registrants/add', reg)
    unitId && await assignRegistrantToUnit(createdId, unitId)
    const createdReg = await fetchOneRegistrant(createdId)
    return createdReg
}

export async function updateRegistrant(
    registrantId: string,
    updatedFields: Partial<Registrant>,
    unit: Unit | null = null,
): Promise<Registrant> {
    if (unit)
        updatedFields.Unit = unit
    await API.lambdaPost(`/registrants/update`, updatedFields)

    const updated = await fetchOneRegistrant(registrantId)
    return updated
}

export async function deleteRegistrant(registrantId: string): Promise<any> {
    await API.lambdaPost(`/registrants/update`, {
        _id: registrantId,
        IsActive: 0,
        Unit: null,
    })
}

export async function createAddressBookContact(residentId: string, name: string, contact: string, sendSMS: boolean, email: string, announcementAlerts: boolean, isLandline: boolean): Promise<any> {

    const response = await API.lambdaPost('/registrants/a4h/add', {
        Registrant: residentId,
        contactName: name,
        contactNumber: contact,
        sendSMS,
        email,
        announcementAlerts,
        isLandline
    });

    return response;
}

export async function createAddressBookComProfileContact(residentId: string, contactName: string, commProfileId: string): Promise<any> {
    const response = await API.lambdaPost('/registrants/a4h/add', {
        Registrant: residentId,
        contactName,
        commProfileId,
    });
    return response;
}
export async function enableInboundCalling(residentId: string, contactId: string): Promise<any> {
    const response = await API.lambdaPost('/registrants/a4h/reciprocalAssociations', {
        Registrant: residentId,
        contactId
    });

    return response;
}

export async function updateAnnouncementAlert(data: object): Promise<any> {
    const response = await API.lambdaPost('/registrants/updateContactAnnouncementAlert', data);
    return response;
}

export async function updateA4HContactName(RegistrantId, RelativeId, Name, type): Promise<any> {
    const params = {
        RegistrantId,
        RelativeId,
        Name,
        type
    };
    const response = await API.lambdaPost('/registrants/a4h/update', params);
    return response;
}

export async function updateA4HEmail(RegistrantId, RelativeId, email, type): Promise<any> {
    const params = {
        RegistrantId,
        RelativeId,
        email,
        type
    };
    const response = await API.lambdaPost('/registrants/a4h/update', params);
    return response;
}


export async function updateA4HContactNumber(RegistrantId, RelativeId, Number, type): Promise<any> {
    const params = {
        RegistrantId,
        RelativeId,
        contactNumber: Number,
        type
    };
    const response = await API.lambdaPost('/registrants/a4h/update', params);
    return response;
}

export async function deleteA4HContact(RegistrantId, RelativeId): Promise<any> {
    const params = {
        RegistrantId,
        RelativeId
    };
    const response = await API.lambdaPost('/registrants/a4h/remove', params);
    return response;
}

export async function deleteRelativeContact(filter: {
    RegistrantId: string,
    RelativeId: string
}): Promise<any> {
    const response = await API.lambdaPost('/registrants/relatives/delete', filter);
    return response;
}

export async function fetchFacilityRegistrants(additionalParams?: object): Promise<any> {
    const residents: any[] = await fetchAllPaginatedResidents(additionalParams)
    return residents.sort((a, b) =>
        a.FirstName + a.LastName < b.FirstName + b.LastName ?
            -1
            :
            a.FirstName + a.LastName > b.FirstName + b.LastName ?
                1
                :
                0
    );
}

export const fetchAllActiveFacilityRegistrants = async (facilityId: string, excludeAlisImage: boolean = false, includeRoomInfo = false, fetchOnPremise: boolean = false) => {
    const projection = { alisImage: excludeAlisImage ? 0 : 1 }
    const filter = { Facility: facilityId, projection, IsActive: 1, includeRoomInfo }
    if (fetchOnPremise) { // show only residents who are on premise
        filter["fetchOnPremise"] = true;
    }
    const res = await API.lambdaPost('/registrants/listAll', filter)
    if (res && res.length) {
        return res.sort((a, b) =>
            a.FirstName + a.LastName < b.FirstName + b.LastName ?
                -1
                :
                a.FirstName + a.LastName > b.FirstName + b.LastName ?
                    1
                    :
                    0
        )
    } else {
        return []
    }
}

export async function fetchResidentStaffCalendarEvents(registrantId: string | null, startDate: string, endDate: string) {
    const Filter = {
        registrantId,
        startDate,
        endDate,
    }

    const response = await API.lambdaPost('/registrants/residentCalendar/list', { Filter })
    if (!response || !response.Result) throw new Error("Cannot fetch notepad entries")

    return response.Result
}

export async function fetchResidentCalendarEvents(registrantId: string | null, startDate: string, endDate: string, residentCalendarId: string = "") {
    const Filter = {
        registrantId,
        startDate,
        endDate,
    }
    if (residentCalendarId) {
        Filter["_id"] = residentCalendarId;
    }

    const response = await API.lambdaPost('/registrants/residentCalendar/list', { Filter })
    if (!response || !response.Result) throw new Error("Cannot fetch notepad entries")

    return response.Result
}

export async function fetchAllMultipleResidentCalendarEvents(
    registrantIds: string[],
    startDate: string,
    endDate: string,
    sort?: any[],
) {
    const params = {
        Filter: {
            registrantIds,
            startDate,
            endDate,
        },
    };
    const sorting = sort && Array.isArray(sort) ? sort : undefined;
    // fetch paginated Resident Calendar Events
    const residentCalendarApiCall = async (params: any) => {
        return await API.lambdaPost('/registrants/residentCalendar/list', params);
    };
    const residentCalendarEvents = await fetchAllPaginatedData(residentCalendarApiCall, params, sorting);
    if (!residentCalendarEvents) throw new Error('Cannot fetch notepad entries');
    return residentCalendarEvents as Partial<ResidentCalendar>[];
}

export async function fetchMultipleResidentCalendarEvents(
    registrantIds: string[],
    startDate: string,
    endDate: string,
    getTotRes: boolean = false,
    pageSort?: { [key: string]: any; }
) {
    const Filter = {
        registrantIds,
        startDate,
        endDate,
    };
    let params = { Filter };
    if (pageSort) {
        params["pageSort"] = { ...pageSort };
    }
    const response = await API.lambdaPost('/registrants/residentCalendar/list', params);
    if (!response || !response.Result) throw new Error('Cannot fetch notepad entries');

    if (getTotRes) {
        return response;
    }
    return response.Result;
}


export async function fetchFacilityResidentCalendarEvents(startDate: string, endDate: string, includedRegistrantAndStaffInfo: boolean = false, searchTerm?: string) {
    const params = {
        Filter: {
            startDate,
            endDate,
            includedRegistrantAndStaffInfo
        },
    };
    if (searchTerm) {
        params["searchTerm"] = searchTerm;
    }
    // fetch paginated Resident Calendar Events
    const residentCalendarApiCall = async (params: any) => {
        return await API.lambdaPost('/registrants/residentCalendar/list', params);
    };
    const residentCalendarEvents = await fetchAllPaginatedData(residentCalendarApiCall, params);
    if (!residentCalendarEvents) throw new Error('Cannot fetch notepad entries');
    return residentCalendarEvents as ResidentCalendar[];
}
export async function fetchFacilityResidentCalendarEventsPaginated(startDate: string, endDate: string, includedRegistrantAndStaffInfo = false, pageSort?: { [key: string]: any; }, searchTerm?: string ) { 
    const Filter = {
        startDate,
        endDate,
        includedRegistrantAndStaffInfo
    };
    let params = { Filter };
    if (pageSort) {
        params["pageSort"] = { ...pageSort };
    }
    if (searchTerm) {
        params["searchTerm"] = searchTerm;
    }
    const response = await API.lambdaPost('/registrants/residentCalendar/list', params);
    if (!response || !response.Result) throw new Error("Cannot fetch notepad entries");

    return response;
 }

export async function createResidentCalendarEvent(residentCalendarEntry: Partial<ResidentCalendar>) {
    const response = await API.lambdaPost('/registrants/residentCalendar/add', residentCalendarEntry)
    if (!response) throw new Error("Could not create a notepad entry")
    return response
}

export async function updateResidentCalendarEvent(residentCalendarEntry: Partial<ResidentCalendar>) {
    const response = await API.lambdaPost('/registrants/residentCalendar/update', {
        _id: residentCalendarEntry._id,
        text: residentCalendarEntry.text,
        dateTimeString: residentCalendarEntry.dateTimeString,
        registrantIds: residentCalendarEntry.registrantIds,
        staffId: residentCalendarEntry.staffId,
        location: residentCalendarEntry.location,
        endDateTimeString: residentCalendarEntry.endDateTimeString,
        relatedServices: residentCalendarEntry.relatedServices,
        syncStatus: residentCalendarEntry.syncStatus,
        syncTimestamp: residentCalendarEntry.syncTimestamp,
    });
    if (!response) throw new Error("Could not update the notepad entry")

    return response
}

export async function deleteResidentCalendarEvent(_id: string) {
    const response = await API.lambdaPost('/registrants/residentCalendar/delete', {
        _id
    })
    if (!response) throw new Error("Could not delete the notepad entry")
    return response
}

export async function upsertResidentInterests(updateData, residentId: string) {
    const body = { residentId, ...updateData }
    try {
        const res = await API.lambdaPost("/registrants/interests/upsert", body)
        return res
    } catch (error) {
        console.error("upsertResidentInterests error", error)
        throw new Error(error instanceof Error ? error.message : "Failed to upsert resident interests")
    }
}

export async function listResidentInterests(residentId?: string, fetchAll?: boolean): Promise<any> {
    try {
        const res = await API.lambdaPost("/registrants/interests/list", { residentId, fetchAll })
        return res
    } catch (error) {
        console.error("listResidentInterests error", error)
        throw new Error(error instanceof Error ? error.message : "Failed to upsert resident interests")
    }
}

export async function listDefaultResidentInterests(): Promise<any> {
    try {
        const res = await API.lambdaGet("/registrants/interests/listDefault");
        return res;
    } catch (error) {
        console.error("listDefaultResidentInterests error", error);
        throw new Error(error instanceof Error ? error.message : "Failed to get resident interests");
    }
}

export async function upsertResidentDietery(updateData: Partial<DietaryState>, residentId: string) {
    const body = { residentId, ...updateData }
    try {
        const res = await API.lambdaPost("/registrants/dietery/upsert", body)
        return res
    } catch (error) {
        console.error("upsertResidentDietary error", error)
        throw new Error(error instanceof Error ? error.message : "Failed to upsert resident dietary")
    }
}
export async function listResidentDietery(residentId: string): Promise<DietaryState> {
    try {
        const res = await API.lambdaPost("/registrants/dietery/list", { residentId })
        return res
    } catch (error) {
        console.error("listResidentDietary error", error)
        throw new Error(error instanceof Error ? error.message : "Failed to upsert resident dietary")
    }
}

export async function getA4hRoomIdFromResidentId(residentId: string) {
    try{
        console.log("residentId =", residentId)
        const res = await API.lambdaPost('/registrants/units/fetch', { registrantId: residentId, getComProfileId: true })
        console.log("getA4hRoomIdFromResidentId res", res)
        return res
    } catch (error) { 
        console.log('getA4hRoomIdFromResidentId error", error');
        throw new Error(error instanceof Error ? error.message : "Failed to get resident a4hRoomId")
    }
}


export async function togglewebrctForRelative(relativeId: string, checked: boolean, facilityId: string) {
    try {
        const res = await API.lambdaPost('/registrants/enableWebrtc', { relativeId, checked, facilityId });
        return res;
    } catch (error) {
        console.log('error in toggle webrct for relative', error);
        throw new Error(error instanceof Error ? error.message : "Failed to update webrtc for relative");
    }
}

export async function fetchUnitIdFromResidentId({ residentId }) {
    try {
        const filter = {
            residentId,
            getUnitId: true
        };
        const res = await API.lambdaPost('/registrants/unit-registrant/get', filter);
        return res;
    } catch (error) {
        console.error("Error in fetchUnitIdFromResidentId", error);
        throw error;
    }
}

export async function fetchResidentIdToCarePlanIdMap(residentIds: string[]) {
    try {
        const filter = {
            residentIds
        };
        const res = await API.lambdaPost('/registrants/getCarePlan', filter);
        return res;
    } catch (error) {
        console.error("Error in fetchResidentIdToCarePlanIdMap", error);
        throw error;
    }
}