import React, { FC, useEffect, useState } from "react";
import { List, Header, Segment } from "semantic-ui-react";
import moment from "moment";
import { fetchAllKioskAnswers } from "../../services/KioskAnswers";
import { KioskAnswersType } from "../../types/KioskAnswers";
import { sendToast } from "../../util";

interface Props {
    startDate: Date;
    endDate: Date;
    showOnlyTopFive?: boolean;
    height?: string;
}

interface VisitorRecord {
    residentName: string;
    totalVisits: number;
}

const TopVisitors: FC<Props> = (props) => {
    const [visitorRecords, setVisitorRecords] = useState<VisitorRecord[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    // Sort and optionally limit to top 5
    const sortedVisitorRecords = [...visitorRecords]
        .sort((a, b) => b.totalVisits - a.totalVisits)
        .slice(0, props.showOnlyTopFive ? 5 : visitorRecords.length);

    const fetchVisitorData = async () => {
        try {
            setIsLoading(true);

            // Convert startDate and endDate to timestamps
            const startTimestamp = moment(props.startDate).valueOf();
            const endTimestamp = moment(props.endDate).valueOf();

            // Fetch all kiosk answers
            const kioskAnswers = await fetchAllKioskAnswers(startTimestamp, endTimestamp);

            // Filter answers to get only those with a residentId
            const residentVisits = kioskAnswers.filter(
                (answer) => answer.residentId && answer.residentName
            );

            // Group and count visits for each resident
            const visitMap = residentVisits.reduce((acc: Record<string, number>, visit: KioskAnswersType) => {
                const residentName = visit.residentName;

                if (residentName) {
                    if (!acc[residentName]) {
                        acc[residentName] = 0;
                    }
                    acc[residentName] += 1;
                }

                return acc;
            }, {} as Record<string, number>);


            // Convert the map to an array
            const visitorRecordsArray = Object.entries(visitMap).map(
                ([residentName, totalVisits]) => ({
                    residentName,
                    totalVisits,
                })
            );

            // Sort the array by total visits in descending order
            const slicedArray = visitorRecordsArray.sort((a, b) => b.totalVisits - a.totalVisits).slice(0, props.showOnlyTopFive ? 5 : visitorRecordsArray.length);

            setVisitorRecords(slicedArray);
        } catch (error) {
            console.error("Error fetching visitor data", error);
            sendToast("error", error instanceof Error ? error.message : "Error fetching visitor data");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchVisitorData();
    }, []);

    const defaultStyle = { width: "300px" };
    const segmentStyle = props.height ? { ...defaultStyle, height: props.height } : defaultStyle;
    return (
        <Segment style={segmentStyle} loading={isLoading}>
            <Header as="h4" style={{ marginBottom: "10px" }}>
                Visitors
            </Header>
            <List divided verticalAlign="middle">
                {sortedVisitorRecords.map((record, index) => (
                    <List.Item key={index}>
                        <List.Content floated="right">
                            <span>{record.totalVisits}</span>
                        </List.Content>
                        <List.Content>
                            <span
                                style={{
                                    display: "inline-block",
                                    padding: "4px 8px",
                                    borderRadius: "4px",
                                }}
                            >
                                {record.residentName}
                            </span>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </Segment>
    );
};

export default TopVisitors;
