import React, { useState, useEffect } from 'react';
import { Grid, Card, Loader, Dimmer, Modal, Form, Button, Dropdown } from 'semantic-ui-react';
import { sendToast } from '../../../util';
import {createCorporate, deleteCorporate, fetchCorporates, updateCorporate} from '../../../services/Corporates';
import { fetchAllActiveFacilities } from '../../../services/Facilities';

interface Corporate {
	id: string;
	Name: string;
	Facilities: string[];
}

interface FacilityOption {
	key: string;
	text: string;
	value: string;
}

const Corporates = (props) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const [facilityOptions, setFacilityOptions] = useState<FacilityOption[]>([]);
	const [corporates, setCorporates] = useState<Corporate[]>([]);
	const [selectedCorporate, setSelectedCorporate] = useState<Corporate | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [corporateForm, setCorporateForm] = useState<{
		Name: string;
		Facilities: string[];
	}>({ Name: '', Facilities: [] });

	const fetchAllFacilities = async () => {
		try {
			setIsLoading(true);
			const facilities = await fetchAllActiveFacilities();
			const facilityOptions = facilities.map((facility) => ({
				key: String(facility._id),
				text: facility.Name,
				value: String(facility._id)
			}));
			setFacilityOptions(facilityOptions);
		} catch (error) {
			sendToast('error', 'Error fetching facilities');
		}
	};

	const fetchAllCorporates = async () => {
		try {
			setIsLoading(true);
			const corporates = await fetchCorporates();
			const data: Corporate[] = corporates.map((corporate) => ({
				id: String(corporate._id),
				Name: corporate.Name,
				Facilities: corporate.Facilities
			}));
			setCorporates(data);
		} catch (error) {
			sendToast('error', 'Error fetching corporates');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchAllCorporates();
		fetchAllFacilities();
	}, []);

	const handleOpenModal = (corporate: Corporate | null) => {
		if (corporate) {
			setIsEditing(true);
			setSelectedCorporate(corporate);
			setCorporateForm({
				Name: corporate.Name,
				Facilities: corporate.Facilities
			});
		} else {
			setIsEditing(false);
			setCorporateForm({
				Name: '',
				Facilities: []
			});
		}
		setIsModalOpen(true);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, { Name, value }: any) => {
		setCorporateForm({ ...corporateForm, [Name]: value });
	};

	const handleSave = async () => {
		try {
			setIsProcessing(true);
			if (isEditing && selectedCorporate) {
				await updateCorporate({ ...corporateForm, _id: String(selectedCorporate.id) });
				sendToast('success', 'Corporate updated successfully');
			} else {
				await createCorporate(corporateForm);
				sendToast('success', 'Corporate created successfully');
			}
			setIsModalOpen(false);
			fetchAllCorporates();
		} catch (error) {
			sendToast('error', error.message || 'Error saving corporate');
		}finally {
			setIsProcessing(false);
		}
	};

	const handleDelete = async () => {
		try {
			setIsProcessing(true);
			if (selectedCorporate) {
				console.log('Deleted corporate:', selectedCorporate.id);
				await deleteCorporate(String(selectedCorporate.id));
				sendToast('success', `Corporate ${selectedCorporate.Name} deleted successfully`);
				setIsModalOpen(false);
				fetchAllCorporates();
			} else {
				sendToast('warn', 'No corporate selected');
			}
		} catch (err) {
			sendToast('error', 'Error deleting corporate');
		} finally {
			setIsProcessing(false);
		}
	};

	return (
		<div style={{ paddingRight: '10em' }}>
			<Dimmer active={isLoading} inverted>
				<Loader active={isLoading} />
			</Dimmer>

			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2em' }}>
				<h1>Corporates</h1>
				<Button primary onClick={() => handleOpenModal(null)}>
					Create Corporate
				</Button>
			</div>

			{isLoading ? (
				<Loader active inline="centered" />
			) : (
					<Grid columns={4} doubling stackable>
						{corporates.map((corporate) => (
							<Grid.Column key={corporate.id} style={{ padding: '1rem' }}>
								<Card
									onClick={() => handleOpenModal(corporate)}
									raised
									fluid
									style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
								>
									<Card.Content>
										<Card.Header>{corporate.Name}</Card.Header>
									</Card.Content>
								</Card>
							</Grid.Column>
						))}
					</Grid>

			)}

			<Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} size="small">
				<Modal.Header>{isEditing ? 'Edit Corporate' : 'Create Corporate'}</Modal.Header>
				<Modal.Content>
					<Form style={{ marginTop: '0px' }}>
						<Form.Input
							label="Name"
							Name="Name"
							value={corporateForm.Name}
							onChange={handleInputChange}
						/>
						<Form.Field>
							<label>Facilities</label>
							<Dropdown
								placeholder="Select Facilities"
								Name="Facilities"
								fluid
								multiple
								selection
								search
								options={facilityOptions}
								value={corporateForm.Facilities}
								onChange={(e, { value }) =>
									setCorporateForm({ ...corporateForm, Facilities: value as string[] })
								}
							/>
						</Form.Field>
					</Form>
				</Modal.Content>
				<Modal.Actions>
					{isEditing && (
						<Button color="red" disabled={isProcessing} onClick={handleDelete}>
							Delete
						</Button>
					)}
					<Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
					<Button primary disabled={isProcessing} onClick={handleSave}>
						Save
					</Button>
				</Modal.Actions>
			</Modal>
		</div>
	);
};

export default Corporates;
