import { Group, GroupAndImage } from '../types/Group';
import { fetchAllPaginatedData } from '../util';
import API from './API';

export async function listGroups(filters = {}): Promise<any> {
    try {
        const groupListApiCall = async (params: any) => { return await API.lambdaPost('/groups/list', params); };
        const response = await fetchAllPaginatedData(groupListApiCall, filters);
        return response;
    } catch (error) {
        console.error('Something went wrong in Listing Groups', error);
        throw new Error(`Something went wrong in Listing Groups ${error}`);
    }
}

export async function listPaginatedGroups({ filters = {}, pageSort = { page_size: 100, page_no: 1, sort: [{ DateAdded: 'asc' }] } }): Promise<any> {
    try {
        const params = {
            pagingSorting: pageSort,
            Filter: filters,
        }
        const response = await API.lambdaPost('/groups/list', params);
        return response;
    } catch (error) {
        console.error('Something went wrong in Listing paginated Groups', error);
        throw new Error(`Something went wrong in Listing paginated Groups ${error}`);
    }
}

export async function createGroup(group: Partial<Group>): Promise<string> {
    try {
        const response = await API.lambdaPost('/groups/add', group);
        return response;
    } catch (error) {
        console.error('Something went wrong in adding Group', error);
        throw new Error(`Something went wrong in adding Group ${error}`);
    }
}

export async function updateGroup(group: Group, imageName: string = ''): Promise<string> {
    try {
        const params = {
            group,
            imageName,
        };
        const response = await API.lambdaPut('/groups/update', params);
        return response;
    } catch (error) {
        console.error('Something went wrong in updating Group', error);
        throw new Error(`Something went wrong in updating Group ${error}`);
    }
}

export async function getOneGroup(id: string): Promise<GroupAndImage> {
    try {
        const response = await API.lambdaGet(`/groups/get?_id=${id}`);
        return response;
    } catch (error) {
        console.error('Something went wrong in fetching one Group', error);
        throw new Error(`Something went wrong in fetching one Group ${error}`);
    }
}

export async function deleteGroup(groupId: string, imageName?: string): Promise<Group> {
    try {
        const response = await API.lambdaDeleteById(`/groups/delete`, { _id: groupId, imageName: imageName });
        return response;
    } catch (error) {
        console.error('Something went wrong in deleting one Group', error);
        throw new Error(`Something went wrong in deleting one Group ${error}`);
    }
}

export async function copyS3GroupImage(
    copiedFromFacilityId: string,
    copiedFromGroupId: string,
    copiedToGroupId: string,
    imageName: string,
): Promise<any> {
    try {
        const response = await API.lambdaPost('/groups/images/copygroupimage', {
            copiedFromFacilityId,
            copiedFromGroupId,
            copiedToGroupId,
            imageName,
        });
        return response;
    } catch (error) {
        console.error('error copyS3GroupImage', error);
        throw new Error(`Something went wrong in copying group image ${error}`);
    }
}

export async function updateMember({
    _id,
    residentId = undefined,
    staffId = undefined,
    remove = false,
}: {
    _id: string;
    residentId?: string;
    staffId?: string;
    remove?: boolean;
}) {
    try {
        const response = await API.lambdaPut('/groups/handleMembers', { _id, residentId, staffId, remove });
        return response;
    } catch (error) {
        console.error('Error occurred while updating group member:', error);
        throw new Error('Failed to update group member');
    }
}
