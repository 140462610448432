
import API from './API';

export const ListResidentMeals = (startDate: string, endDate: string, includeResidentsName?: boolean) => {
    try {
        const response = API.lambdaPost("/registrants/meals/list", {
            startDate,
            endDate, 
            includeResidentsName
        });
        return response
    } catch (error) {
        console.error(error);
        throw error;
    }
}